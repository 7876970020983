<template>
    <div class="vx-card p-6">
        <div class="flex flex-wrap items-center">
            <div class="flex-grow headlr" >
                <span>{{$t('root.titlePackage')}}</span>
            </div>

            <div class="vx-row w-full">
                <div class="vx-col md:w-3/12 sm:w-auto">
                    <label class="vs-input--label">{{ $t('menuCamera.organization') }}</label>
                    <v-select class="" v-model="selectedOrganization" :clearable="false"
                              :options="organizationOptions">
                        <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-3/12 sm:w-auto">
                    <label class="vs-input--label">{{ $t('menuCamera.cameraName') }}</label>
                    <v-select class="" v-model="selectedCamera" :clearable="false"
                              :options="cameraOptions">
                        <span slot="no-options">{{$t('menuOrganization.cameraEmpty')}}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-2/12 sm:w-auto">
                    <vs-button @click="filterCamera" class="mt-6 vx-col ml-2" type="border" >{{ $t('button.filter') }}
                    </vs-button>
                </div>
                <div class="vx-col md:w-4/12 sm:w-auto" >
                    <div class="vx-row w-full mt-5" style="justify-content: end; flex-wrap: nowrap !important;">
                            <vs-input class=" sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                            v-model="searchQuery"
                            @input="updateSearchQuery" :placeholder="$t('button.search')"/>
                    </div>
                </div>

            </div>
        </div>
        <!-- AgGrid Table -->
        <ag-grid-vue style="height: 55vh"
                     ref="agGridTable"
                     :components="components"
                     :gridOptions="gridOptions"
                     class="ag-theme-material w-100 my-4 ag-grid-table"
                     :columnDefs="columnDefsO"
                     :defaultColDef="defaultColDef"
                     :rowData="packageHistory"
                     :animateRows="true"
                     :pagination="true"
                     :localeText=" {noRowsToShow: this.$t('root.noRowsToShow')}"
                     :paginationPageSize="paginationPageSize"
                     :onGridReady="onGridReady"
                     :suppressPaginationPanel="true">
        </ag-grid-vue>
        <div class="vx-row items-center">
            <div class="vx-col md:w-1/2">
                <Pagination
                    :itemList="packageHistory"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
            </div>
            <div class="vx-col md:w-1/2">
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {AgGridVue} from "ag-grid-vue"
    import vSelect from 'vue-select';
    import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
    import Pagination from "../../ui-elements/Pagination.vue"
    import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
    import CellRendererStatus from "./cell-renderer/CellRendererStatus";
    import moment from 'moment'
    import _enum from "@/enum";

    export default {
        components: {
            AgGridVue,
            vSelect,
            // Cell Renderer
            CellRendererActions,
            Pagination,
            CellRendererStatus
        },
        data() {
            return {
                organizationOptions: [{value: null, label: this.$t('root.allOrgan')}],
                searchQuery: "",
                maxPageNumbers: 7,
                // AgGrid
                gridApi: null,
                gridOptions: {},
                defaultColDef: {},
                columnDefsO: [
                    {
                        headerName: this.$t('root.organization'),
                        field: 'organizationBranchId',
                        width: 100,
                    },
                    {
                        headerName: this.$t('root.cameraAlias'),
                        field: 'alias',
                        width: 70
                    },
                    {
                        headerName: this.$t('root.packageName'),
                        field: 'cameraPackageResponseDTO.packageName',
                        width: 80,
                    },
                    {
                        headerName: 'Số tháng',
                        field: 'time',
                        width: 50,
                    },
                    {
                        headerName: this.$t('menuPackage.modifiedDate'),
                        field: 'modifiedDate',
                        width: 80
                    },
                    {
                        headerName: this.$t('root.status'),
                        field: 'registerPackageStatus',
                        width: 50,
                        cellRendererFramework: 'CellRendererStatus'
                    },
                    {
                        headerName: this.$t('button.actions'),
                        field: 'actions',
                        width: 50,
                        cellRendererFramework: 'CellRendererActions',
                    },
                ],
                // Cell Renderer Components
                components: {
                    CellRendererActions,
                    CellRendererStatus
                },
                packageHistory: [],
                organizationList: [],
                cameraOptions: [{value: null, label: this.$t('menuOrganization.cameraEmpty')}],
                branchId: null,
                selectedOrganizationValue: null,
                selectedCameraValue: null
            }
        },
        async created() {
            await this.getOrganization();
            this.initScreen();
        },
        watch: {
            '$store.state.windowWidth'(val) {
                if (val <= 1024) {
                    this.maxPageNumbers = 5;
                } else {
                    this.maxPageNumbers = 7;
                }

                if (val > 1024) {
                    this.gridApi.sizeColumnsToFit();
                }
            },
            '$store.state.reduceButton'() {
                setTimeout(() => {
                    this.gridApi.sizeColumnsToFit();
                }, 650);
            },
            '$store.state.isUpdateOrgPackageRequestPackage'(val) {
                if (val) {
                    this.initScreen();
                    this.$store.commit('UPDATE_REQUEST_PACKAGE', false);
                }
            },
        },
        computed: {
            selectedOrganization: {
                get() {
                    return {
                        label: this.organizationString(this.selectedOrganizationValue),
                        value: this.selectedOrganizationValue
                    }
                },
                set(obj) {
                    this.selectedOrganizationValue = obj.value
                    this.cameraOptions = [{
                        value: null,
                        label: this.$t('menuOrganization.cameraEmpty')
                    }]
                    if(obj.value != null){
                        this.loadCameras();
                    } else {
                        this.initScreen()
                    }
                }
            },
            selectedCamera: {
                get() {
                    return {
                        label: this.cameraString(this.selectedCameraValue),
                        value: this.selectedCameraValue
                    }
                },
                set(obj) {
                    this.selectedCameraValue = obj.value
                }
            },
            paginationPageSize() {
                if (this.gridApi) return this.gridApi.paginationGetPageSize();
                else return 20
            },
            totalPages() {
                if (this.gridApi) return this.gridApi.paginationGetTotalPages();
                else return 0
            },
            currentPage: {
                get() {
                    if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                    else return 1
                },
                set(val) {
                    this.gridApi.paginationGoToPage(val - 1)
                }
            }
        },
        methods: {
            getInfoCamera(camera) {
                this.packageHistory = [];
                this.$vs.loading();
                let params = {}
                if(camera.value) {
                    params['cameraId'] = camera.value
                }
                if(this.selectedOrganizationValue) {
                    params['organizationId'] = this.selectedOrganizationValue
                }
                let url = '/camera-package-history/find-by-admin?' + (new URLSearchParams(params).toString());
                this.$crm.get(url).then((response) => {
                    this.packageHistory = response.data.map(x => {
                        x.cameraName = camera.label;
                        let org = this.organizationList.find(org => org.id === x.organizationId);
                        x.organizationBranchId = org ? org.name : "";
                        x.modifiedDate = moment(x.modifiedDate).format("DD/MM/YYYY HH:mm:ss");
                        return x;
                    }).sort((a, b) => b.id - a.id)

                    this.$vs.loading.close();
                    setTimeout(() => {
                        if (this.$store.state.windowWidth > 1024) {
                            this.gridApi.sizeColumnsToFit();
                        }
                    }, 100);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            },

            loadCameras() {
                this.cameraOptions = [];
                this.$vs.loading();
                let path = "/camera/admin/all";
                if (this.selectedOrganizationValue) {
                    path = "/camera/admin/find-by-organization/" + this.selectedOrganizationValue;
                }
                this.$vs.loading();
                this.$crm.post(path).then((response) => {
                    this.cameraOptions.push(...response.data.map(item => {
                        return {
                            value: item.id,
                            label: item.cameraName,
                            aliasId: item.aliasId
                        }
                    }));
                    this.cameraOptions.unshift({
                        value: null,
                        label: this.$t('menuOrganization.cameraEmpty')
                    })
                    this.$vs.loading.close();
                    setTimeout(() => {
                        if (this.$store.state.windowWidth > 1024) {
                            this.gridApi.sizeColumnsToFit();
                        }
                    }, 100);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            },
            organizationString(id) {
                let organization = this.organizationOptions.find(x => x.value === id);
                return (organization && organization.label) || this.$t('root.null');
            },
            cameraString(id) {
                if(id != null){
                    let camera = this.cameraOptions.find(x => x.value === id);
                    return (camera && camera.label) || this.$t('menuOrganization.cameraEmpty');
                } else {
                    let camera = this.cameraOptions.find(x => x.value === id);
                    return (camera && camera.label) || this.$t('menuOrganization.cameraEmpty');
                }
            },
            updateSearchQuery(val) {
                this.gridApi.setQuickFilter(val)
            },
            onGridReady() {
                if (this.$store.state.windowWidth <= 1024) {
                    this.maxPageNumbers = 5;
                } else {
                    this.maxPageNumbers = 7;
                }

                if (this.$store.state.windowWidth > 1024) {
                    this.gridApi.sizeColumnsToFit();
                }
            },
            initScreen() {
                this.packageHistory = [];
                this.$vs.loading();
                let url = '/camera-package-history/find-by-admin';
                let params = {};
                if (this.selectedOrganizationValue) params['organizationId'] = this.selectedOrganizationValue;
                this.$crm.get(url.concat("?" + new URLSearchParams(params).toString())).then((response) => {
                    this.packageHistory = response.data.map(x => {
                        let org = this.organizationList.find(org => org.id === x.organizationId);
                        x.organizationBranchId = org ? org.name : "";
                        x.modifiedDate = moment(x.modifiedDate).format("DD/MM/YYYY HH:mm:ss");
                        return x;
                    }).sort((a, b) => b.id - a.id)
                    this.$vs.loading.close();
                    setTimeout(() => {
                        if (this.$store.state.windowWidth > 1024) {
                            this.gridApi.sizeColumnsToFit();
                        }
                    }, 100);
                    this.currentPage = 1;
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
                this.loadCameras();
            },

            getOrganization() {
                return new Promise(resolve => {
                    this.$vs.loading();
                    this.$oauth.post('/organization/admin/all').then((response) => {
                        this.organizationList = response.data;
                        this.organizationOptions.push(...response.data.map(item => {
                            return {
                                value: item.id,
                                label: item.name
                            }
                        }));
                        this.$vs.loading.close();
                        resolve(true);
                    }).catch((err) => {
                        resolve(true);
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    });
                });
            },
            filterCamera() {
                this.getInfoCamera({
                    value: this.selectedCameraValue
                })
            }
        },
        mounted() {
            this.gridApi = this.gridOptions.api;
        }
    }
</script>

<style lang="scss" scoped>
.headlr {
    font-size: 18px;
    font-weight: 600;
    text-align: start;
}
</style>
